import React from 'react';
import serenescapeBanner from '../assets/serenescape-banner.png';
import './projects.css';

const Projects: React.FC = () => {
    const projects = [
        {
            title: "Serenescape",
            description: "A meditation and mindfulness application designed to elevate your wellness journey",
            image: serenescapeBanner,
            link: "/serenescape",
            technologies: ["Swift", "SwiftUI"]
        }
    ];

    return (
        <section className="projects" id="projects">
            <div className="projects-grid">
                {projects.map((project, index) => (
                    <a key={index} href={project.link} target="_blank" rel="noopener noreferrer" className="project-card">
                        <div className="project-image">
                            <img src={project.image} alt={project.title} />
                        </div>
                        <div className="project-content">
                            <h3>{project.title}</h3>
                            <p>{project.description}</p>
                            <div className="project-technologies">
                                {project.technologies.map((tech, index) => (
                                    <span key={index} className="tech-tag">{tech}</span>
                                ))}
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </section>
    );
};

export default Projects;
