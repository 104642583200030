import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/home';
import About from './components/about';
import Contact from './components/contact';
import Header from './components/header';
import SerenescapePage from './pages/serenescape';
import PrivacyPolicy from './pages/privacy';
import './assets/hiragino.ttc';
import Projects from './components/projects';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/serenescape" element={<SerenescapePage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

const Main: React.FC = () => {
  return (
    <>
      <Header showNav="true" />
      <Home />
      <About />
      <Projects />
      <Contact />
    </>
  )
}

export default App;
