import React from 'react';
import scott from '../assets/scott.jpg';

const Home: React.FC = () => {
    return (
        <section className="home" id="home">
            <div className="hero">
                <img src={scott} alt="Scott Finkelstein Headshot" />
                <h1>
                    <span style={{ color: '#8FD3E9' }}>Technologist</span><br />
                    <span style={{ color: '#71D26F' }}>Leader</span><br />
                    <span style={{ color: '#FFD465' }}>Developer</span>
                </h1>
            </div>
        </section>
    );
};

export default Home;