import React from 'react';
import { FaLinkedin, FaGithub, FaInstagram } from 'react-icons/fa';
import './contact.css';

const Contact: React.FC = () => {
    return (
        <section className="contact" id="contact">
            <div className="content">
                <h2>Connect With Me</h2>
                <div className="social-links">
                    <a href="https://www.linkedin.com/in/scottbfinkelstein/" target="_blank" rel="noreferrer" aria-label="LinkedIn">
                        <FaLinkedin />
                    </a>
                    <a href="https://github.com/scottfinkelstein" target="_blank" rel="noreferrer" aria-label="GitHub">
                        <FaGithub />
                    </a>
                    <a href="https://www.instagram.com/scott.finkelstein/" target="_blank" rel="noreferrer" aria-label="Instagram">
                        <FaInstagram />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Contact;