import { Link } from 'react-scroll';

interface HeaderProps {
    showNav: string;
}

const Header: React.FC<HeaderProps> = (props) => {
    const { showNav } = props;
    return (
        <header>
            <Link to="home" spy={true} smooth={true} offset={-100} duration={400} className="logo">Scott Finkelstein</Link>
            { showNav === "true" &&
                <nav>
                    <Link
                        to="about"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={400}
                        className='nav-link'
                        activeClass='active'
                    >
                        About
                    </Link>
                    <Link
                        to="projects"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={400}
                        className='nav-link'
                        activeClass='active'
                    >
                        Projects
                    </Link>
                    <Link
                        to="contact"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={400}
                        className='nav-link'
                        activeClass='active'
                    >
                        Contact
                    </Link>
                </nav>
            }
        </header>
    );
};

export default Header;